import React from 'react'
import { useViewport } from 'hooks'
import { Link, Icon } from 'routes/components/Shared'

export const LandingConnectors = () => {
  const { isMobile } = useViewport()

  return (
    <div className='landing__section landing-connectors'>
      <div className='flex-column landing-connectors__body'>
        <h2 className='page__title landing-connectors__title'>
          Connect to any third-party app on behalf of your customer
        </h2>

        <div className='landing-connectors__content'>
          <div className='page__background landing-connectors__background' />

          <div className='landing-connectors__description'>
            <div className='landing-connectors__description-item'>
              <div className='description-card'>
                <h3 className='description-card__title'>
                  Hundreds of the most popular connectors available out of the
                  box.
                </h3>

                <p className='description-card__content'>
                  See{' '}
                  <Link type='line' href='/connectors'>
                    list of connectors.
                  </Link>
                </p>

                {isMobile && (
                  <Icon
                    className='description-card__vector'
                    type='connectors-visual-vector'
                    preserveAspectRatio='xMinYMin slice'
                  />
                )}
              </div>
            </div>

            <div className='landing-connectors__description-item'>
              <div className='description-card'>
                <p className='description-card__content'>
                  Tens of thousands of niche apps available via{' '}
                  <span>Connector Builder.</span>
                </p>

                {isMobile && (
                  <Icon
                    className='description-card__vector'
                    type='connectors-visual-vector'
                    preserveAspectRatio='xMinYMin slice'
                  />
                )}
              </div>
            </div>
          </div>

          <Icon
            className='landing-connectors__visual'
            type={`connectors-visual--${isMobile ? 'sm' : 'lg'}`}
          />
        </div>
      </div>
    </div>
  )
}
