import React from 'react'
import { useViewport } from 'hooks'

export const LandingApplicationCard = ({ card }) => {
  const { imageLg, imageSm, title, description } = card
  const { isMobile } = useViewport()

  const getImage = () => {
    if (isMobile && imageSm) {
      return imageSm
    } else {
      return imageLg
    }
  }

  return (
    <div className='application-card'>
      <div className='application-card__content'>
        <h3 className='application-card__title'>{title}</h3>

        <p className='application-card__description'>{description}</p>
      </div>

      <div className='application-card__visual'>
        <img src={getImage().src} alt='landing application card' />
      </div>
    </div>
  )
}
