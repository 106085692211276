import React from 'react'
import { useViewport } from 'hooks'
import { Link } from 'routes/components/Shared'

import MonitoringImageSm from './landing-monitoring--sm.png'
import MonitoringImageLg from './landing-monitoring--lg.png'

export const LandingMonitoring = () => {
  const { isMediumMobile } = useViewport()

  return (
    <div className='landing__section landing-monitoring'>
      <div className='flex-column landing-monitoring__body'>
        {!isMediumMobile && (
          <div className='page__background landing-monitoring__background' />
        )}

        <h2 className='page__title landing-monitoring__title'>
          State of the art monitoring and troubleshooting toolbox for
          integrations
        </h2>

        <p className='page__subtitle landing-monitoring__subtitle'>
          Troubleshooting often takes more time than building. We help you by
          providing unprecedented transparency into the inner workings of your
          integrations.
        </p>

        <div className='page__actions landing-monitoring__actions'>
          <Link
            className='page__button landing-monitoring__button'
            type='secondary'
            href='https://console.integration.app/docs/monitoring-and-troubleshooting'
          >
            Learn more
          </Link>
        </div>

        <div className='landing-monitoring__visual'>
          <div className='landing-monitoring__visual-dots' />
          <img
            src={(isMediumMobile ? MonitoringImageSm : MonitoringImageLg).src}
            alt='landing monitoring'
          />
        </div>
      </div>
    </div>
  )
}
