import React from 'react'
import { useViewport } from 'hooks'
import { Icon } from 'routes/components/Shared'

export const LandingService = () => {
  const { isMobile } = useViewport()

  return (
    <div className='landing__section landing-service'>
      <div className='flex-column landing-service__body'>
        <h2 className='page__title landing-service__title'>
          Build UX your customers love
        </h2>

        <p className='page__subtitle landing-service__subtitle'>
          User interface is the hardest part of building native integrations. We
          make it simple.
        </p>

        <div className='landing-service__items'>
          <div className='page__background landing-service__background' />

          {!isMobile && (
            <>
              <div className='landing-service__square landing-service__square--left' />
              <div className='landing-service__square landing-service__square--right' />
            </>
          )}

          <ul className='flex-column landing-service__items-list'>
            <li className='landing-service__item landing-service__item--1'>
              <p>Start with</p>
            </li>

            <li className='landing-service__item landing-service__item--2'>
              <Icon
                className='landing-service__item-icon landing-service__item-icon--sales-arrow'
                type='sales-arrow'
              />

              <p className='landing-service__item-button'>Connect</p>

              <Icon
                className='landing-service__item-icon landing-service__item-icon--sales-force'
                type='sales-force'
              />
            </li>

            <li className='landing-service__item landing-service__item--3'>
              <p>one-click integration</p>
            </li>
          </ul>
        </div>

        <p className='page__subtitle landing-service__description'>
          Simply display the “Connect” button in your UI - we take care of the
          rest.
        </p>
      </div>
    </div>
  )
}
