import React from 'react'
import { PartnersBlock } from 'routes/components/Features'

export const LandingPartners = () => {
  return (
    <PartnersBlock
      className='landing-partners'
      title='Backed by experts in SaaS automation'
    />
  )
}
