import React from 'react'
import { LandingComplexCard } from './LandingComplexCard'
import { complexArray } from './complexArray'

export const LandingComplex = () => {
  if (process.env.NODE_ENV === 'production') {
    return null
  }
  return (
    <div className='landing-complex'>
      <div className='flex-column landing-complex__body'>
        <h2 className='page__title landing-complex__title'>
          Works for the simplest and the most complex use cases
        </h2>

        <div className='landing-complex__items'>
          <ul className='flex-column landing-complex__items-list'>
            {complexArray.map((card, index) => {
              return (
                <li className='landing-complex__item' key={index}>
                  <LandingComplexCard card={card} />
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}
