import React from 'react'
import { useViewport } from 'hooks'
import { Link, Icon } from 'routes/components/Shared'
import { LandingPatternContent } from './LandingPatternContent'

export const LandingPattern = () => {
  const { isMediumMobile } = useViewport()

  return (
    <div className='landing__section landing-pattern'>
      <div className='flex-column landing-pattern__body'>
        {!isMediumMobile && (
          <div className='page__background landing-pattern__background' />
        )}

        <h2 className='page__title landing-pattern__title'>
          Automatically scale integration scenarios to dozens of apps
        </h2>

        <p className='page__subtitle landing-pattern__subtitle'>
          Our Al-powered engine automatically generates application-specific
          integrations based on a common pattern.
        </p>

        <LandingPatternContent />

        <div className='page__actions landing-pattern__actions'>
          <Link
            className='btn--ai page__button landing-pattern__button'
            type='secondary'
            href='/how-we-use-ai'
          >
            <Icon type='ai-structure' />
            <span>Powered by AI</span>
            <Icon type='ai-arrow' />
          </Link>
        </div>
      </div>
    </div>
  )
}
