import LandingApplicationProductImageLg from './landing-application-product--lg.png'
import LandingApplicationProductImageSm from './landing-application-product--sm.png'
import LandingApplicationInteractImageLg from './landing-application-interact--lg.png'
import LandingApplicationInfrastructureImageLg from './landing-application-infrastructure--lg.png'

export const applicationArray = [
  {
    title: 'Store integration logic as YAML alongside your product code',
    description:
      'Edit, test, and version control your integrations like you would any other part of your application.',
    imageSm: LandingApplicationProductImageSm,
    imageLg: LandingApplicationProductImageLg,
  },
  {
    title: 'Let your users interact with integrations anywhere in your app',
    description:
      'Trigger integrations from anywhere in your UI and inject data from external applications seamlessly.',
    imageLg: LandingApplicationInteractImageLg,
  },
  {
    title: 'Connect to your existing infrastructure',
    description:
      'Integrations should use the same logging, monitoring, notifications, and other parts of your infrastructure.',
    imageLg: LandingApplicationInfrastructureImageLg,
  },
]
