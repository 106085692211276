import Icon1 from './icon-1.png'
import Icon2 from './icon-2.png'
import Icon3 from './icon-3.png'

export const advantagesArray = [
  {
    icon: Icon1,
    title: '<p>Custom development is</p><p>slow and tedious</p>',
    description:
      'It takes weeks to build a high-quality native integration. And then you have to repeat it dozens of times.',
  },
  {
    icon: Icon2,
    title:
      '<p>iPaaS are shallow and lead to poor</p><p>customer experience</p>',
    description:
      'Sending customers to a third-party platform or embedding foreign iframes into your app just does not cut it for the modern user.',
  },
  {
    icon: Icon3,
    title: '<p>Unified APls are just not</p><p>flexible enough</p>',
    description:
      'Implementing basic use cases quickly, but facing challenges with non-standard needs, often requiring a complete restart',
  },
]
