import React, { useEffect, useState } from 'react'
import { useViewport } from 'hooks'
import { LandingCasesCard } from './LandingCasesCard'
import { casesArray } from './casesArray'

import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'

export const LandingCases = () => {
  return (
    <div className='landing-cases'>
      <div className='flex-column landing-cases__body'>
        <h2 className='page__title landing-cases__title'>
          The next generation of fast-growing SaaS companies are building with
          us
        </h2>

        <Slider />
      </div>
    </div>
  )
}

function Slider() {
  const { isMobile, isTablet } = useViewport()
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  if (!isClient) {
    return null
  }

  const slidesCountHandler = () => {
    if (casesArray.length <= 1 && isMobile) {
      return true
    } else if (casesArray.length <= 2 && isTablet && true) {
      return true
    } else if (casesArray.length <= 3 && !isTablet) {
      return true
    } else {
      return false
    }
  }

  const carouselSettings = {
    infinite: true,
    disableButtonsControls: true,
    disableDotsControls: slidesCountHandler(),
    touchTracking: !slidesCountHandler(),
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      992: {
        items: 3,
      },
    },
  }

  return (
    <div className='landing-cases__slider'>
      <AliceCarousel
        className='landing-cases__slider-list'
        {...carouselSettings}
      >
        {casesArray.map((card, index) => {
          return (
            <div className='landing-cases__slide' key={index}>
              <LandingCasesCard card={card} />
            </div>
          )
        })}
      </AliceCarousel>
    </div>
  )
}
