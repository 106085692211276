import React from 'react'
import { LandingBuildingBlocksCard } from './LandingBuildingBlocksCard'
import { buildingBlocksArray } from './buildingBlocksArray'

export const LandingBuildingBlocks = () => {
  return (
    <div className='landing-building-blocks'>
      <div className='flex-column landing-building-blocks__body'>
        <div className='page__background landing-building-blocks__background' />

        <h2 className='page__title landing-building-blocks__title'>
          No reinventing the wheel. No black boxes.
        </h2>

        <p className='page__subtitle landing-building-blocks__subtitle'>
          Building great integrations can be tricky. Our integration framework
          is made of well-engineered building blocks that take care of all the
          hard parts and edge cases but don't stand in your way.
        </p>

        <div className='landing-building-blocks__items'>
          <div className='landing-building-blocks__items-listWrapper'>
            <ul className='landing-building-blocks__items-list'>
              {buildingBlocksArray.map((card, index) => {
                return (
                  <li className='landing-building-blocks__item' key={index}>
                    <LandingBuildingBlocksCard card={card} />
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
