import {
  LandingAdvantages,
  LandingApplication,
  LandingBanner,
  LandingBuildingBlocks,
  LandingCases,
  LandingComplex,
  LandingConnectors,
  LandingMonitoring,
  LandingPartners,
  LandingPattern,
  LandingService,
  LandingStructure,
  LandingUI,
} from './components'
import { DefaultPageLayout } from 'routes/layouts'

export default function IndexPage() {
  return (
    <DefaultPageLayout>
      <div className='builder'>
        <div className='container'>
          <div className='builder__body'>
            <LandingBanner />
            <LandingCases />
            <LandingAdvantages />
            <LandingComplex />
            <LandingConnectors />
            <LandingBuildingBlocks />
            <LandingPattern />
            <LandingApplication />
            <LandingMonitoring />
            <LandingStructure />
            <LandingService />
            <LandingUI />
            <LandingPartners />
          </div>
        </div>
      </div>
    </DefaultPageLayout>
  )
}
