import React from 'react'
import AwsLogoImage from './aws-logo.png'
import CertificateLogoImage from './certificate-logo.png'
import DockerLogoImage from './docker-logo.png'
import KubernetesLogoImage from './kubernetes-logo.png'

export const LandingStructure = () => {
  return (
    <div className='landing__section landing-structure'>
      <div className='flex-column landing-structure__body'>
        <h2 className='page__title landing-structure__title'>
          Run on your infrastructure or in the cloud
        </h2>

        <p className='page__subtitle landing-structure__subtitle'>
          Integrations are a key component of your product. You can't afford
          them to be disconnected.
        </p>

        <div className='landing-structure__items'>
          <ul className='landing-structure__items-list'>
            <li className='landing-structure__item landing-structure__item--certificate'>
              <div className='structure-card'>
                <h3 className='structure-card__title'>
                  Certified world-class security
                </h3>

                <p className='structure-card__description'>
                  We are SOC-2 Type 2 certified, GDPR-compliant, and can address
                  any of your or your customer's security concerns.
                </p>

                <ul className='structure-card__content-list'>
                  <li className='structure-card__content-item'>
                    <img
                      src={CertificateLogoImage.src}
                      alt='certificate logo'
                      loading={'lazy'}
                    />
                  </li>

                  <li className='structure-card__content-item'>
                    <p>SOC-2 certified</p>
                    <p>GDPR compliant</p>
                  </li>
                </ul>
              </div>
            </li>

            <li className='landing-structure__item landing-structure__item--deployment'>
              <div className='structure-card'>
                <h3 className='structure-card__title'>On-premise Deployment</h3>

                <p className='structure-card__description'>
                  You can run our engine using Docker on your own
                  infrastructure.
                </p>

                <ul className='structure-card__content-list'>
                  <li className='structure-card__content-item'>
                    <img
                      src={AwsLogoImage.src}
                      alt='aws logo'
                      loading={'lazy'}
                    />
                  </li>

                  <li className='structure-card__content-item'>
                    <img
                      src={DockerLogoImage.src}
                      alt='docker logo'
                      loading={'lazy'}
                    />
                  </li>

                  <li className='structure-card__content-item'>
                    <img
                      src={KubernetesLogoImage.src}
                      alt='kubernetes logo'
                      loading={'lazy'}
                    />
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
