import React from 'react'
import { classNames } from 'utils'
import { Link, Icon } from 'routes/components/Shared'

export const LandingUICard = ({ card }) => {
  const { type, image, title, description, link1, link2, background } = card

  return (
    <div
      className={classNames([
        'flex-column',
        'ui-card',
        background && 'ui-card--background',
      ])}
    >
      {background && <Icon className='ui-card__background' type={background} />}

      <div className='ui-card__visual'>
        <img src={image.src} alt='landing ui card' />
      </div>

      <div className='ui-card__content'>
        <h3 className='ui-card__title'>{title}</h3>

        <p className='ui-card__description'>{description}</p>

        {link1 && link2 && (
          <div className='page__actions ui-card__actions'>
            <h4 className='ui-card__actions-title'>Documentation:</h4>

            <Link
              className='page__button ui-card__button'
              type='secondary'
              href={link1.href}
            >
              {link1.value}
            </Link>

            <Link
              className='page__button ui-card__button'
              type='secondary'
              href={link2.href}
            >
              {link2.value}
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}
