import React from 'react'
import { PatternCardType } from './patternArray'
import { useViewport } from 'hooks'
import { Link, Icon } from 'routes/components/Shared'

export const LandingPatternCard = ({ card }: { card?: PatternCardType }) => {
  const { isMediumMobile } = useViewport()

  if (!card) {
    return null
  }

  const { imageLg, imageSm, description, link } = card

  return (
    <div className='pattern-card'>
      <div className='pattern-card__visual'>
        <img
          src={(isMediumMobile ? imageSm : imageLg).src}
          alt='landing pattern'
        />
      </div>

      <p className='page__subtitle pattern-card__description'>
        {description}
        {link && (
          <>
            {' '}
            <Link type='noLine' href={link}>
              Learn more
              <Icon type='chevron' />
            </Link>
          </>
        )}
      </p>
    </div>
  )
}
