import React from 'react'
import { LandingApplicationCard } from './LandingApplicationCard'
import { applicationArray } from './applicationArray'

export const LandingApplication = () => {
  return (
    <div className='landing__layer landing-application'>
      <div className='flex-column landing-application__body'>
        <h2 className='page__title landing-application__title'>
          Own your integrations
        </h2>

        <p className='page__subtitle landing-application__subtitle'>
          Keep integrations a part of your code and infrastructure. Integrations
          are a key component of your product - you can't afford them to be
          owned by a third party.
        </p>

        <div className='landing-application__content'>
          <div className='landing-application__top'>
            <div className='landing-application__block landing-application__block--product'>
              <LandingApplicationCard card={applicationArray[0]} />
            </div>
          </div>

          <div className='flex-column landing-application__bottom'>
            <div className='landing-application__block landing-application__block--interact'>
              <LandingApplicationCard card={applicationArray[1]} />
            </div>

            <div className='landing-application__block landing-application__block--infrastructure'>
              <LandingApplicationCard card={applicationArray[2]} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
