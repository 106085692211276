import React from 'react'
import { classNames } from 'utils'
import { LandingUICard } from './LandingUICard'
import { uiArray } from './uiArray'

export const LandingUI = () => {
  return (
    <div className='landing-ui'>
      <div className='flex-column landing-ui__body'>
        <div className='page__background landing-ui__background' />

        <h2 className='page__title landing-ui__title'>
          Use pre-built UI components or create your own
        </h2>

        <p className='page__subtitle landing-ui__subtitle'>
          Make your own tradeoff between fast development and customization -
          from a single line of code to fully custom UI.
        </p>

        <div className='landing-ui__items'>
          <ul className='landing-ui__items-list'>
            {uiArray.map((card, index) => {
              return (
                <li
                  className={classNames([
                    'landing-ui__item',
                    `landing-ui__item--${card.type}`,
                  ])}
                  key={index}
                >
                  <LandingUICard card={card} />
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}
