import React from 'react'

export const LandingAdvantagesCard = ({ card }) => {
  const { icon, title, description } = card

  return (
    <div className='flex-column advantages-card'>
      <div className='page__background advantages-card__background' />
      <img src={icon.src} className='advantages-card__visual' alt={''} />

      <h3
        className='advantages-card__title'
        dangerouslySetInnerHTML={{ __html: title }}
      />

      <p className='advantages-card__description'>{description}</p>
    </div>
  )
}
