import DataImage from './landing-ui-data.png'
import MappingsImage from './landing-ui-mappings.png'
import ObjectsImage from './landing-ui-objects.png'
import ScenariosImage from './landing-ui-scenarios.png'
import ServiceImage from './landing-ui-service.png'

export const uiArray = [
  {
    type: 'mappings',
    image: MappingsImage,
    title: 'Field and value mappings',
    description:
      "Everyone's setup is different - let your users map data between your app and external apps.",
    link1: {
      value: 'Drop-in UI',
      href: 'https://console.integration.app/docs/ui/field-mappings',
    },
    link2: {
      value: 'Build it Yourself',
      href: 'https://console.integration.app/docs/ui/field-mappings/custom',
    },
  },
  {
    type: 'scenarios',
    image: ScenariosImage,
    title: 'Enable or disable integration scenarios',
    description:
      'Let your users customize which integrations they want to run.',
    link1: {
      value: 'Drop-in UI',
      href: 'https://console.integration.app/docs/ui/integration',
    },
    link2: {
      value: 'Build it Yourself',
      href: 'https://console.integration.app/docs/ui/integration/custom',
    },
    background: 'ui-scenarios',
  },
  {
    type: 'service',
    image: ServiceImage,
    title: 'Self-service monitoring and troubleshooting',
    description:
      'When something goes wrong with an integration, give your customers a way to fix it without reaching out to support.',
  },
  {
    type: 'objects',
    image: ObjectsImage,
    title: 'Object Links',
    description:
      'Let users configure relations between objects in your app and an external app.',
    background: 'ui-objects',
  },
  {
    type: 'data',
    image: DataImage,
    title: 'Data Locations',
    description:
      'Your users can decide where to import data from or export data to.',
    link1: {
      value: 'Drop-in UI',
      href: 'https://console.integration.app/docs/ui/data-sources',
    },
    link2: {
      value: 'Build it Yourself',
      href: 'https://console.integration.app/docs/ui/data-sources/custom',
    },
  },
]
