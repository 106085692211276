import { StaticImageData } from 'next/image'
import { DOCS_LINK } from '../../../constants'
import { TabType } from 'routes/components/Shared'
import ImageActionsSm from './landing-pattern-actions--sm.png'
import ImageActionsLg from './landing-pattern-actions--lg.png'
import ImageFlowsSm from './landing-pattern-flows--sm.png'
import ImageFlowsLg from './landing-pattern-flows--lg.png'
import ImageEventsSm from './landing-pattern-events--sm.png'
import ImageEventsLg from './landing-pattern-events--lg.png'

export type PatternCardType = TabType & {
  imageSm: StaticImageData
  imageLg: StaticImageData
  description: string
  link?: string
}

export const patternArray: PatternCardType[] = [
  {
    tab: 'actions',
    title: 'Universal Actions',
    mobileTitle: 'Actions',
    description:
      'Universal actions represents help you perform similar requests (like "Create a task", "Get list of users", or "Send a message") to many different apps at once.',
    link: `${DOCS_LINK}/ui/actions`,
    imageSm: ImageActionsSm,
    imageLg: ImageActionsLg,
  },
  {
    tab: 'flows',
    title: 'Universal Flows',
    mobileTitle: 'Flows',
    description:
      'Universal flows implement complex multi-step integration scenarios across dozens of apps. You or your customers can customize the logic for a specific application.',
    link: `${DOCS_LINK}/ui/flows`,
    imageSm: ImageFlowsSm,
    imageLg: ImageFlowsLg,
  },
  {
    tab: 'events',
    title: 'Universal Events',
    mobileTitle: 'Events',
    description:
      'Universal events let you subscribe to similar things (data changes, messages, updates) that happen across many different applications.',
    imageSm: ImageEventsSm,
    imageLg: ImageEventsLg,
  },
]
