import React from 'react'
import { classNames } from 'utils'
import { Link, Icon } from 'routes/components/Shared'

export const LandingCasesCard = ({ card }) => {
  const { topic, href, description, logo, status, time } = card

  return (
    <div className={classNames(['case-card', status && 'case-card--active'])}>
      <div className='flex-column case-card__body'>
        <div className='case-card__title-block'>
          <p className='case-card__topic'>{topic}</p>

          <Link className='case-card__link' href={href}>
            {status && <Icon type='case-arrow' />}
          </Link>
        </div>

        <div className='case-card__logo'>
          <img src={logo.src} alt='case logo' />
        </div>

        <p className='case-card__description'>{description}</p>
      </div>
    </div>
  )
}
