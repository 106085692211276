import AirmeetLogo from './airmeet-logo.svg'
import GlyphicLogo from './glyphic-logo.svg'
import PotionLogo from './potion-logo.svg'
import WhalesyncLogo from './whalesync-logo.svg'
import CustomerOSLogo from './customer-os-logo.svg'

export const casesArray = [
  {
    topic: 'Events Management',
    href: '/articles/case-studies/airmeet',
    description:
      "Airmeet is a virtual event platform that allows users to easily import and export event participants. The platform allows users to import participants from a CSV file, which can be created using a spreadsheet program like Excel. Once the participants are imported, they can be easily managed and organized within the platform. Airmeet also allows users to export a list of participants, which can be useful for follow-up communication or for tracking attendance. The export feature generates a CSV file that can be opened and edited in a spreadsheet program. Overall, Airmeet's import and export features provide a convenient and efficient way for users to manage event participants.",
    logo: AirmeetLogo,
    status: true,
  },
  {
    topic: 'AI Sales',
    href: '/articles/case-studies/glyphic',
    description:
      "Glyphic is an emerging leader in AI-driven Sales space. Learn how it integrated with its customers's sales stack.",
    logo: GlyphicLogo,
    status: true,
  },
  {
    topic: 'AI Video',
    href: '/articles/case-studies/potion',
    description:
      'Potion is an AI video personalisation tool. See how they combined custom and our drop-in integration UI to let their users enjoy more connectivity.',
    logo: PotionLogo,
    status: true,
  },
  {
    topic: 'Data integration',
    href: '',
    description:
      'WhaleSync is a data integration platform. They accelerated their connector roadmap with AI.',
    logo: WhalesyncLogo,
    status: false,
  },
  {
    topic: 'Analytics',
    href: '',
    description:
      'CustomerOS is a customer data and analytics platform. They sync customer data through us',
    logo: CustomerOSLogo,
    status: false,
  },
]
