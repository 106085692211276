import React from 'react'
import { Link } from 'routes/components/Shared'
import { LandingAdvantagesCard } from './LandingAdvantagesCard'
import { advantagesArray } from './advantagesArray'

export const LandingAdvantages = () => {
  return (
    <div className='landing__section landing-advantages'>
      <div className='landing-advantages__body'>
        <div className='flex-column landing-advantages__top'>
          <h2 className='page__title landing-advantages__title landing-advantages__title--top'>
            There was no good way to build <nobr>customer-facing</nobr>{' '}
            integrations.
            <br />
            Until now.
          </h2>

          <div className='landing-advantages__items'>
            <ul className='landing-advantages__items-list'>
              {advantagesArray.map((card, index) => {
                return (
                  <li className='landing-advantages__item' key={index}>
                    <LandingAdvantagesCard card={card} />
                  </li>
                )
              })}
            </ul>
          </div>
        </div>

        <div className='flex-column landing-advantages__bottom'>
          <h2 className='page__title landing-advantages__title landing-advantages__title--bottom'>
            Imagine: <nobr>world-class</nobr> <nobr>in-house</nobr> integration
            framework, without years of engineering.
          </h2>

          <p className='page__subtitle landing-advantages__subtitle'>
            You can have development speed of iPaaS, scalability of Unified
            APIs, and flexibility of custom engineering. All in one framework,
            seamlessly working together.
          </p>

          <div className='page__actions landing-advantages__actions'>
            <Link
              className='page__button landing-advantages__button'
              type='primary'
              href='https://console.integration.app/login'
            >
              Try For Free
            </Link>

            <Link
              className='page__button landing-advantages__button'
              type='secondary'
              href='/how-we-are-different'
            >
              See how exactly are we different
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
