import React, { useEffect, useState } from 'react'
import { useViewport } from 'hooks'
import { classNames } from 'utils'
import { Link, Icon } from 'routes/components/Shared'

export const LandingBuildingBlocksCard = ({ card }) => {
  const { title, icon, description, href, puzzleJigs } = card

  return (
    <div className='building-block-card'>
      <h3 className='building-block-card__title'>
        <Icon className='building-block-card__icon' type={icon} />
        <Link href={href}>{title}</Link>
      </h3>

      <p className='building-block-card__description'>{description}</p>

      <PuzzleJigs {...puzzleJigs} />
    </div>
  )
}

function PuzzleJigs({ xl, lg, md, sm }) {
  const { isTablet, isMobile, isMediumMobile } = useViewport()
  const [isBrowser, setIsBrowser] = useState(false)

  useEffect(() => {
    setIsBrowser(true)
  }, [])

  let setOfJigs

  switch (true) {
    case isMediumMobile:
      setOfJigs = sm
      break
    case isMobile:
      setOfJigs = md
      break
    case isTablet:
      setOfJigs = lg
      break
    default:
      setOfJigs = xl
  }

  if (!isBrowser) return null

  return (
    <>
      {setOfJigs.map((location) => (
        <Jig key={location} location={location} />
      ))}
    </>
  )
}

function Jig({ location }) {
  const className = classNames([
    'building-block-card__line',
    `building-block-card__line--${location}`,
    location === 'top' || location === 'bottom'
      ? 'building-block-card__line--horizontal'
      : 'building-block-card__line--vertical',
  ])

  const type =
    location === 'top' || location === 'bottom'
      ? 'puzzle-jig--horizontal'
      : 'puzzle-jig--vertical'

  return (
    <Icon
      className={className}
      type={type}
      preserveAspectRatio='xMinYMin slice'
      fill={'none'}
    />
  )
}
