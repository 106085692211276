import React from 'react'
import { BannerBlock } from 'routes/components/Features'
import { bannerData } from './bannerData'

export const LandingBanner = () => {
  return (
    <BannerBlock
      className={bannerData.className}
      title={bannerData.title}
      isBookButton
    />
  )
}
