import React, { useState } from 'react'
import { Tabs } from 'routes/components/Shared'
import { patternArray } from './patternArray'
import { LandingPatternCard } from './LandingPatternCard'

export const LandingPatternContent = () => {
  const [activeTab, setActiveTab] = useState(patternArray[0].tab)
  const activeCard = patternArray.find((card) => card.tab === activeTab)

  return (
    <>
      <Tabs
        className='landing-pattern__navigation'
        tabsData={patternArray}
        activeTab={activeTab}
        onTabClick={(tab) => setActiveTab(tab?.tab)}
      />

      <div className='landing-pattern__content'>
        <LandingPatternCard card={activeCard} />
      </div>
    </>
  )
}
